import React, { Fragment, cloneElement, Children, useEffect, useContext } from 'react';
import Layout from '../components/layout';
import { InView } from 'react-intersection-observer';
import SEO from '../components/seo';
import NotFound404 from '../sections/404';
// wrap all sections with inView component - watch if any sections are out of viewport
const Sections = ({ children, width }) => {
	return (
		<div className={`g-grid__${width}`}>
			{Children.map(children, (child, index) => (
				<InView key={index} threshold={0.6}>
					{({ inView, ref, entry }) => cloneElement(child, { ref, isVisible: inView })}
				</InView>
			))}
		</div>
	);
};

const Error404 = () => {
	return (
		<Layout>
			<SEO title="404 Error"/>
			<Sections width="full">
				<NotFound404/>
			</Sections>
		</Layout>
	);
};

export default Error404;