import React, { forwardRef, useEffect, useState, useRef } from 'react';
import Sections from '../index';

const NotFound = forwardRef(({ isVisible }, ref) => {
	return (
		<div className="page" ref={ref}>
			404 Not Found ...
		</div>
	);
});

NotFound.displayName = '404';

export default NotFound;
